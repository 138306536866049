import { PageCategory } from '../../types/PageCategory'
import homepage from './homepage'
import list from './list'
import page from './page'
import catalog from './catalog'
import product from './product'
import search from './search'
import cart from './cart'
import login from './login'
import savedCarts from './savedCarts'
import licences from './licences'
import favorites from './favorites'
import myData from "./myData"
import orders from "./orders";
import web2print from "./web2print";
import pdfCollection from "./pdfCollection";
import checkout from "./checkout";

export default {
  [PageCategory.HOMEPAGE]: homepage,
  [PageCategory.PRODUCT]: product,
  [PageCategory.SEARCH]: search,
  [PageCategory.LIST]: list,
  [PageCategory.CATALOG]: catalog,
  [PageCategory.CART]: cart,
  [PageCategory.PAGE]: page,
  [PageCategory.ORDERS]: orders,
  [PageCategory.LOGIN]: login,
  [PageCategory.SAVED_CARTS]: savedCarts,
  [PageCategory.LICENCES]: licences,
  [PageCategory.FAVORITES]: favorites,
  [PageCategory.MY_DATA]: myData,
  [PageCategory.PERSONALIZED_DOCUMENTS]: web2print,
  [PageCategory.PDF_COLLECTION]: pdfCollection,
  [PageCategory.CHECKOUT]: checkout
}
