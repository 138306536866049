import { ActionType } from "~/types/Action";
import { computed } from "@nuxtjs/composition-api";
import { DeliveryType } from "~/types/DeliveryType";
import { ProductDetailType } from "~/types/ProductData";
import { mapProductToProductDetails } from "~/utils/mapProductToProductDetails";
import useCart from "~/modules/checkout/composables/useCart";
import { useLicense } from "~/composables";
import { useProductVersion } from "~/composables/useProductVersion";
import { getChildProductBySku, isDigitalOnly, isPhysicalOnly } from "~/composables/useProductDetails/productType";
import useWishlist from "~/modules/wishlist/composables/useWishlist";
import { usePrintOnDemand } from "~/modules/web2print/composables/usePrintOnDemand";
import productGetters from "~/modules/catalog/product/getters/productGetters";

export function useProductDetails(product) {
  const { canAddToCart } = useCart();
  const { isInWishlist } = useWishlist();

  const { sku, skuPhysical, skuDigital } = useProductVersion(product);
  const { isLicenseExpired, hasLicense } = useLicense(product);

  const digitalProduct = computed(() => {
    const { product: digitalProductItem } = getChildProductBySku(product.value, skuDigital.value);

    return digitalProductItem;
  });

  const physicalProduct = computed(() => {
    const { product: physicalProductItem } = getChildProductBySku(product.value, skuPhysical.value);

    return physicalProductItem;
  });

  const printOnDemand = usePrintOnDemand(digitalProduct);
  const isProductInWishlist = computed(() => isInWishlist({ product: product.value }));

  const isDownloadable = computed(() => {
    return (
      (product.value?.delivery_type === DeliveryType.DIGITAL_OR_PHYSICAL || product.value?.delivery_type === DeliveryType.DIGITAL_ONLY) &&
      product.value?.active_versions.digital.sku != ""
    );
  });

  const activeVersionSku = computed(() => {
    return skuDigital.value || skuPhysical.value;
  });

  const imageUrl = computed(() => {
    const { product: physicalProductItem } = getChildProductBySku(product.value, activeVersionSku.value);
    if (physicalProductItem) {
      return productGetters.getCoverImage(physicalProductItem);
    }
    return "";
  });

  const isPhysicalProduct = computed((): boolean => {
    return isPhysicalOnly(product.value?.delivery_type);
  });

  const isHybridProduct = computed(() => {
    return product.value?.delivery_type === DeliveryType.DIGITAL_OR_PHYSICAL;
  });

  const isDigitalProduct = computed(() => {
    return isDigitalOnly(product.value?.delivery_type);
  });

  const isAddToCartVisible = computed(() => {
    if (isDigitalProduct.value) {
      return false;
    }

    if (hasLicense.value && isLicenseExpired.value) {
      return false;
    }

    if (!canAddToCart(physicalProduct.value, 1)) {
      return false;
    }

    return true;
  });

  const isPersonalisable = computed(() => {
    if (printOnDemand.isPrintOnDemand.value) {
      return true;
    }
    return false;
  });

  const detailKeys = computed(() => {
    let detailKeysList = [ProductDetailType.ARTICLE_NUMBER, ProductDetailType.VERSION_PRINT, ProductDetailType.VERSION_DOWNLOAD];

    if (skuPhysical.value !== '') {
      detailKeysList.push(ProductDetailType.AVAILABILITY);
    }

    if (hasLicense.value) {
      if (isLicenseExpired.value) {
        detailKeysList.push(ProductDetailType.LICENCE_EXPIRED);
      } else {
        detailKeysList.push(ProductDetailType.LICENCE);
      }
    }

    detailKeysList.push(ProductDetailType.PRICE)

    return detailKeysList;
  });

  const actions = computed(() => {
    let actionList = [];

    if (isDownloadable.value) {
      actionList.push(ActionType.DOWNLOAD);
    }

    if (isProductInWishlist.value) {
      actionList.push(ActionType.FAVORITE_REMOVE);
    } else {
      actionList.push(ActionType.FAVORITE);
    }

    if (printOnDemand.isPrintOnDemand.value) {
      actionList.push(ActionType.PERSONALIZE);
    }

    if (isDownloadable.value) {
      actionList.push(ActionType.ADD_TO_COLLECTION);
    }

    return actionList;
  });

  const details = computed(() => mapProductToProductDetails(product.value, detailKeys.value));

  return {
    sku,
    skuDigital,
    activeVersionSku,
    isDownloadable,
    isAddToCartVisible,
    isPersonalisable,
    isPhysicalProduct,
    details,
    actions,
    imageUrl,
    printOnDemand,
  };
}
