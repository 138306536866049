export enum PageCategory {
  HOMEPAGE = 'homepage',
  PRODUCT = 'product',
  SEARCH = 'search',
  CATALOG = 'catalog',
  LIST = 'list',
  PAGE = 'page',
  ORDERS = 'orders',
  ORDER_DETAILS = 'order_details',
  FAVORITES = 'favorites',
  LICENCES = 'licences',
  PERSONALIZED_DOCUMENTS = 'personalized_documents',
  LOGIN = 'login',
  CART = 'cart',
  SAVED_CARTS = 'saved_carts',
  PDF_COLLECTION = 'pdf_collection',
  MY_DATA = 'my_data',
  CHECKOUT = 'checkout'
}

export enum Device {
  MOBILE = 'mobile',
  TABLET = 'tablet',
}
