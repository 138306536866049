import { defineStore } from 'pinia';
import { CategoryResult } from '~/composables';
import categoryListGql from './graphql/categoryList.gql';

interface CategoryState {
  rawCategories: CategoryResult | null
  isLoading: boolean
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
    isLoading: false
  }),
  actions: {
    async load() {
      if(this.isLoading){
        return;
      }
      this.isLoading = true;
      const { data }: { data: { categories?: CategoryResult } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({ query: categoryListGql });
      this.rawCategories = data?.categories ?? null;
      this.isLoading = false;
    }
  },
  getters: {
    categories(state) {
      if (state.rawCategories === null) {
        return null;
      }

      return state.rawCategories?.items[0];
    },
  },
});
