import { Component } from "vue";

export interface Action {
  type: ActionType
  icon?: ActionIcon
  onClick?: Function
  component?: Component
  params?: Record<string, any>
}

export interface LinkAction extends Action {
  url: string
}

export enum ActionType {
  ADD_TO_CART = 'actions_addToCart',
  REMOVE_FROM_CART = 'actions_removeFromCart',
  PERSONALIZE = 'action_personalize',
  DOWNLOAD = 'action_download',
  ACCEPT_LICENSE = 'action_acceptToLicense',
  ADD_TO_COLLECTION = 'action_addToCollection',
  REMOVE_FROM_COLLECTION = 'action_removeFromCollection',
  FAVORITE = 'action_favorite',
  PREVIEW = 'action_preview',
  FAVORITE_REMOVE = 'action_removeFavorite',
  REMOVE_PERSONALIZED = 'action_removePersonalized',
  QUANTITY = 'action_quantity',
  DETAILS = 'action_details',
  MORE='action_more'
}

export enum ActionTypeScreenreader {
  ADD_TO_CART = 'screenreader_actions_addToCart',
  ADD_TO_CART_QUANTITY = 'screenreader_actions_addToCart_quantity',
  SET_QUANTITY_FOR_CART = 'screenreader_actions_set_quantity',
  ADD_TO_CART_CANCEL = 'screenreader_actions_addToCart_cancel',
  ADD_TO_CART_SUCCESS = 'screenreader_actions_addToCart_success',
  REMOVE_FROM_CART = 'screenreader_actions_removeFromCart',
  PERSONALIZE = 'screenreader_action_personalize',
  DOWNLOAD = 'screenreader_action_download',
  ACCEPT_LICENSE = 'screenreader_action_acceptToLicense',
  ADD_TO_COLLECTION = 'screenreader_action_addToCollection',
  REMOVE_FROM_COLLECTION = 'screenreader_action_removeFromCollection',
  FAVORITE = 'screenreader_action_favorite',
  PREVIEW = 'screenreader_action_preview',
  FAVORITE_REMOVE = 'screenreader_action_removeFavorite',
  REMOVE_PERSONALIZED = 'screenreader_action_removePersonalized',
  QUANTITY = 'screenreader_action_quantity',
  DECREMENT_QUANTITY = 'screenreader_action_decrement_quantity',
  INCREMENT_QUANTITY = 'screenreader_action_increment_quantity',
  QUANTITY_INPUT_LABEL = 'screenreader_action_quantity_input_label',
  DETAILS = 'screenreader_action_details',
  MORE='screenreader_action_more'
}

export enum ActionIcon {
  EMPTY_CART = 'dsb-cart-functional-icon',
  ORDER = 'dsb-order-icon',
  SHOPPING_LIST = 'dsb-shopping-list-icon',
  DOWNLOAD = 'dsb-download-icon',
  EDIT = 'dsb-edit-icon',
  FAVORITE_ADD = 'dsb-favorite-icon',
  PAPERCLIP = 'dsb-paperclip-icon',
  PREVIEW = 'dsb-preview-icon',
  REMOVE = 'dsb-remove-icon',
  INFO = 'dsb-info-icon',
  HEART_FULL = 'dsb-heart-full-icon'
}
