export enum Banktransfer {
  PAYMENT_PAGE_PAYMENT_TEXT_LINE_1 = 'banktransfer_payment_page_payment_text_line_1',
  PAYMENT_PAGE_PAYMENT_TEXT_LINE_2 = 'banktransfer_payment_page_payment_text_line_2',
  ORDER_CONFIRMATION_PAGE_TEXT_LINE_1 = 'banktransfer_order_confirmation_payment_text_line_1',
  ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_TITLE = 'banktransfer_order_confirmation_payment_information_title',
  ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_RECEIVER_NAME = 'banktransfer_order_confirmation_payment_information_receiver_name',
  ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_BANK_NAME = 'banktransfer_order_confirmation_payment_information_bank_name',
  ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_IBAN = 'banktransfer_order_confirmation_payment_information_iban',
  ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_BIC = 'banktransfer_order_confirmation_payment_information_bic',
  ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_AMOUNT = 'banktransfer_order_confirmation_payment_information_amount',
  ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_REFERENCE = 'banktransfer_order_confirmation_payment_information_reference',
  ORDER_CONFIRMATION_PAGE_TEXT_LINE_2 = 'banktransfer_order_confirmation_payment_text_line_2',
  ORDER_CONFIRMATION_PAGE_TEXT_LINE_3 = 'banktransfer_order_confirmation_payment_text_line_3',
  ORDER_CONFIRMATION_PAGE_TEXT_LINE_4 = 'banktransfer_order_confirmation_payment_text_line_4',
  ORDER_CONFIRMATION_PAGE_TEXT_LINE_5 = 'banktransfer_order_confirmation_payment_text_line_5',
}
