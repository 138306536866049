import {Banktransfer} from "~/types/checkout/Banktransfer";
import {Payment} from "~/types/checkout/Payment";

export default {
  [Banktransfer.PAYMENT_PAGE_PAYMENT_TEXT_LINE_1]:  'Wenn Sie mit der Bezahlart „Vorkasse“ zahlen möchten, erhalten Sie nach dem Bestellvorgang alle notwendigen Zahlungsinformationen, um den Bezahlvorgang per Überweisung ausführen zu können.',
  [Banktransfer.PAYMENT_PAGE_PAYMENT_TEXT_LINE_2]: 'Bitte beachten Sie, das der Rechnungsbetrag innerhalb von 3 Tagen nach Bestellung von Ihnen überwiesen werden muss. Der Versand erfolgt erst nach Zahlungseingang und Verbuchung.',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_TEXT_LINE_1]: 'Vielen Dank für Ihre Bestellung im Allianz-Medienportal. Wir haben Ihre Bestellung erhalten und bitten Sie, den Rechnungsbetrag in Höhe von {amount} innerhalb von 3 Tagen per Vorkasse zu überweisen.',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_TITLE]: 'Zahlungsinformationen:',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_RECEIVER_NAME]: 'Empfänger:',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_BANK_NAME]: 'Bank:',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_IBAN]: 'IBAN:',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_BIC]: 'BIC:',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_AMOUNT]: 'Betrag:',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_PAYMENT_INFORMATION_REFERENCE]: 'Verwendungszweck:',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_TEXT_LINE_2]: 'Bitte achten Sie darauf, die Bestellnummer als Verwendungszweck anzugeben, damit wir Ihre Zahlung zuordnen können.',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_TEXT_LINE_3]: 'Nach Zahlungseingang und Verbuchung wird Ihre Ware umgehend versendet.',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_TEXT_LINE_4]: 'Mit freundlichen Grüßen',
  [Banktransfer.ORDER_CONFIRMATION_PAGE_TEXT_LINE_5]: 'Ihr Allianz Kundenservice von Arvato',
  [Payment.PAYMENT_PAGE_PAYMENT_NOT_SELECTED_ERROR_MESSAGE]: 'Bitte wählen Sie eine Zahlungsmethode aus'
}
