import { LoginGeneral, LoginLink, LoginEnterId, LoginError } from "../../types/login/LoginCategories";

export default {
  [LoginGeneral.TITLE]: "Willkommen im Shop für die schönsten Drucksachen der Welt!",
  [LoginGeneral.INTRO_TEXT]: "Bitte melden Sie sich an.",

  [LoginLink.SSO]: "Allianz Login (SSO)",
  [LoginLink.CONTINUE]: "Weiter",
  [LoginLink.CONTINUE_WITH_NUMBER]: "Weiter mit dieser Vermittlernummer",
  [LoginLink.CONTINUE_WITH_NUMBER_SCREENREADER]: "Weiter mit der Vermittlernummer {debitorId}",
  [LoginLink.CONTINUE_AS_INTERN]: "Weiter als interner Nutzer",
  [LoginLink.APPLY_FOR_MEMBERSHIP]: "Mitgliedschaft beantragen",
  [LoginLink.WRITE_EMAIL]: "Support anschreiben",
  [LoginLink.MAILTO]: "mailto:allianz@arvato.com",

  [LoginEnterId.INTERN_INTRO]: "",
  [LoginEnterId.KNOWN_ID]: "Diese Vermittlernummer ist uns bekannt:",
  [LoginEnterId.SELECT_INFO_ID]: "Diese Vermittlernummern sind uns bekannt:",
  [LoginEnterId.SELECT_LABEL_ID]: "Bitte wählen Sie die gewünschte Vermittlernummer aus",
  [LoginEnterId.SELECT_PLACEHOLDER_ID]: "Vermittlernummer",
  [LoginEnterId.INPUT_INFO_ID]: "Bitte geben Sie die gewünschte Vermittlernummer ein",
  [LoginEnterId.INPUT_LABEL_ID]: "Vermittlernummer",
  [LoginEnterId.INPUT_PLACEHOLDER_ID]: "Vermittlernummer",

  [LoginError.SOMETHING_HAPPENED]: "Es ist ein Fehler aufgetreten:",
  [LoginError.MAYBE_THIS_HELPS]: "Bitte kontaktieren Sie den Support oder versuchen Sie es erneut.",
  [LoginError.CUSTOMER_NOT_FOUND]: "Ihr Kundenkonto existiert nicht, oder verfügt nicht über ausreichende Berechtigungen.",
  [LoginError.GENERAL_ERROR]: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  [LoginError.INVALID_TOKEN]: "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an, ggf. müssen Sie Ihre Cookies löschen.",
  [LoginError.REQUEST_ERROR]: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
};
